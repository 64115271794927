import { createContext, ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import toast from "react-hot-toast";
import ability from "../../configs/acl/ability";
import { api, getRequest, postRequest } from "../../services/api";
import { getIsStudent, getPermissionsUser } from "../Utils";
import { useGetResendMailVerify } from "../hooks/auth/useGetAuth";
import { laravelEcho } from "../../services/websocket";
import moment from "moment";
import { IStudentPlan } from "../types/student";
export type SignInCredentials = {
  email?: string;
  password: string;
  cpf?: string;
  logging_in: "STUDENT" | "EMPLOYEE";
  isAdmin?: boolean;
  remember?: boolean;
};

type AuthContextData = {
  signIn(credentials: SignInCredentials): Promise<void>;
  signOuth(): Promise<void>;
  isAuthenticated: boolean;
  user: User | false;
  error: string | null;
  success: string | null;
  loading: boolean | null;
  handleSetSuccess: (messege) => void;
  handleSetError: (error) => void;
  studentPlans?: IStudentPlan[];
  notPaid: boolean;
  setNotPaid;
  setUser;
};

type AuthProviderProps = {
  children: ReactNode;
};

type User = {
  email: string;
  permissions: string[];
  roles: string[];
};

export const AuthContext = createContext({} as AuthContextData);

export function AuthProvider({ children }: AuthProviderProps) {
  const MySwal = withReactContent(Swal);

  const [user, setUser] = useState<User | false>(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean | null>(null);
  const [studentPlans, setStudentPlans] = useState<IStudentPlan[]>();
  const [notPaid, setNotPaid] = useState(false);
  const isAuthenticated = user ? true : false;
  const navigate = useNavigate();
  const { mutate, isLoading } = useGetResendMailVerify();
  const handleSetSuccess = (message) => {
    setSuccess(message);
  };
  const handleSetError = (error) => {
    setError(error);
  };
  async function signIn({
    email,
    password,
    logging_in,
    cpf,
  }: SignInCredentials) {
    try {
      setLoading(true);
      setNotPaid(false);
      const {
        user,
        token,
        validateStatusPlanPay,
        hasVerifiedEmail,
        studentPlans: dataStudentPlans,
      } = await postRequest("login", {
        email,
        password,
        logging_in,
        cpf,
      });

      if (!hasVerifiedEmail) {
        Swal.fire({
          text: "Algo deu errado na verificação do email, por favor clique em reenviar email de verificação",
          icon: "info",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Reenviar email de verificação",
        }).then((result) => {
          if (result.isConfirmed) {
            mutate(user.id);
          }
        });
        setLoading(false);
        return;
      }
      const isStudent = user.roles.some((item) => item.name == "Candidato");
      console.log(dataStudentPlans);
      if (isStudent && validateStatusPlanPay == "STUDENT_PLAN_NOT_PAID") {
        setNotPaid(true);
        setStudentPlans(dataStudentPlans);
        setLoading(false);
        navigate("/login");
        return;
      }

      localStorage.setItem("@revalida.token", JSON.stringify(token));
      localStorage.setItem("@revalida.user", JSON.stringify(user));

      setUser(user);
      api.defaults.headers["Authorization"] = `Bearer ${token}`;
      if (laravelEcho.connector.options.auth.headers["Authorization"]) {
        laravelEcho.connector.options.auth.headers[
          "Authorization"
        ] = `Bearer ${token}`;
      }

      const permissions: any = getPermissionsUser(user.roles, user.permissions);
      ability.update(permissions);

      setError(null);

      const redirect = localStorage.getItem("@revalida.redirect");

      if (isStudent) {
        if (validateStatusPlanPay == "STUDENT_PLAN_PAID") {
          navigate(redirect ? JSON.parse(redirect) : "/dashboard/home");
          localStorage.removeItem("@revalida.redirect");
          infoStudentPlans(dataStudentPlans);
        } else if (
          validateStatusPlanPay == "STUDENT_PLAN_NOT_PAID" ||
          validateStatusPlanPay == "STUDENT_PLAN_NOT_EXIST"
        ) {
          noticeStudent(validateStatusPlanPay);
        }
      } else {
        navigate(redirect ? redirect : "/admin/home");
        localStorage.removeItem("@revalida.redirect");
      }
    } catch (err: any) {
      setSuccess(null);
      if (err?.code == "ERR_NETWORK") {
        setError("Algo deu errado!. Tente novamente mais tarde");
      } else {
        if (err?.response?.data == "STUDENT_PLAN_ANALYSIS") {
          noticeStudent(err?.response?.data);
        } else if (
          err?.response?.data ==
          "Desculpe mas ainda não temos fase ativa no sistema"
        ) {
          infoPhaseInactive();
        } else if (err?.response?.data == "Em manutenção") {
          navigate("/manutencao");
        } else if (
          err?.response?.data.validateStatusPlanPay == "STUDENT_PLAN_NOT_EXIST"
        ) {
          localStorage.setItem(
            "@revalida.student",
            JSON.stringify(err?.response?.data.student)
          );
          noticeStudent("STUDENT_PLAN_NOT_EXIST");
        } else {
          if (typeof err?.response?.data == "object") {
            setError(JSON.stringify(err?.response?.data));
          } else {
            setError(err?.response?.data);
          }
        }
      }
      console.log(err);
    }

    setLoading(false);
  }
  async function signOuth() {
    const isStudent = getIsStudent();
    try {
      toast.loading("Carregando...");
      await getRequest(`logout`);
      localStorage.removeItem("@revalida.user");
      localStorage.removeItem("@revalida.token");
      setSuccess("Deslogado com sucesso");
      setError(null);
      laravelEcho.leaveAllChannels();

      navigate(isStudent ? `/login` : `/admin/login`);
    } catch (err: any) {
      setSuccess(null);
      setError(err.data);
      if (err?.response?.data == "Acesso não autorizado") {
        localStorage.removeItem("@revalida.user");
        localStorage.removeItem("@revalida.token");
        setSuccess("Deslogado com sucesso");
        setError(null);
        navigate(isStudent ? `/login` : `/admin/login`);
      } else if (err?.response?.data.status == "NO_PLAN_IN_FORCE") {
        localStorage.removeItem("@revalida.user");
        localStorage.removeItem("@revalida.token");
        navigate(isStudent ? `/login` : `/admin/login`);
      } else {
        MySwal.fire({
          text: err,
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
      console.log(err);
    }
    toast.remove();
  }
  type StatusPlanPay =
    | "STUDENT_PLAN_NOT_PAID"
    | "STUDENT_PLAN_NOT_EXIST"
    | "STUDENT_PLAN_PAID"
    | "STUDENT_PLAN_ANALYSIS";
  const noticeStudent = (validateStatusPlanPay: StatusPlanPay) => {
    if (validateStatusPlanPay == "STUDENT_PLAN_ANALYSIS") {
      MySwal.fire({
        text: "Olá candidato, vimos que você adquiriu um plano, mas o pagamento ainda não foi confirmado",
        showDenyButton: false,
        showConfirmButton: false,
        showCancelButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: "Acessar o histórico",
        denyButtonText: `Voltar`,
        cancelButtonText: `Fechar`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        // if (result.isConfirmed) {
        //   navigate("/dashboard/home");
        // }
        // else if (result.isDenied) {
        //   signOuth();
        // }
      });
    }
    if (validateStatusPlanPay == "STUDENT_PLAN_NOT_EXIST") {
      MySwal.fire({
        // title: "Vamos para a próxima etapa!",
        text: "Olá candidato, vimos que você não adquiriu nenhum plano, que tal contratar um plano agora?",
        showDenyButton: true,
        showCancelButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: "Contratar plano",
        denyButtonText: `Fechar`,
        // cancelButtonText: "Fechar",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          navigate("/adquirir-plano");
        } else if (result.isDenied) {
          localStorage.removeItem("@revalida.student");
        }
      });
    }
  };

  const infoPhaseInactive = () => {
    MySwal.fire({
      text: "Olá candidato, ainda não temos nenhuma fase disponível no sistema, com isso não será possível entrar no sistema",
      icon: "info",
      showDenyButton: false,
      showConfirmButton: false,
      showCancelButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      confirmButtonText: "Acessar o histórico",
      denyButtonText: `Voltar`,
      cancelButtonText: `Fechar`,
    }).then((result) => {});
  };

  const infoStudentPlans = (studentPlans) => {
    if (studentPlans?.length > 0) {
      const paymentInProgress: string[] = [];
      const paymentExpired: string[] = [];

      studentPlans.map((item: any) => {
        if (item.status_payment == "Em Andamento") {
          paymentInProgress.push(item?.plan.name ?? `Plano ${item.plan_id}`);
        }
        if (item.status_payment == "Concluído" && item.expired_access != null) {
          const checkDate = moment(item.expired_access).isBefore(
            moment(),
            "date"
          );
          if (checkDate) {
            paymentExpired.push(item?.plan.name ?? `Plano ${item.plan_id}`);
          }
        }
      });
      if (paymentInProgress.length > 0 || paymentExpired.length > 0) {
        MySwal.fire({
          title: "O bastidores do revalida informa!",
          icon: "warning",
          html: componentAlertPlan(paymentInProgress, paymentExpired),
          showCloseButton: true,
          showCancelButton: false,
          confirmButtonText: `OK`,
        });
      }
    }
  };

  return (
    <AuthContext.Provider
      value={{
        signIn,
        isAuthenticated,
        user,
        error,
        success,
        loading,
        signOuth,
        handleSetError,
        handleSetSuccess,
        studentPlans,
        notPaid,
        setNotPaid,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

const componentAlertPlan = (paymentInProgress, paymentExpired) => {
  const oneInProgress = paymentInProgress.length == 1;
  const oneExpired = paymentExpired.length == 1;
  const totalExpired = paymentExpired?.length + paymentInProgress?.length > 1;
  return (
    <div>
      {paymentInProgress.length > 0 && (
        <>
          <p>
            {oneInProgress ? "O Plano " : "Os planos "}{" "}
            {paymentInProgress.join(", ")} {oneInProgress ? " está" : " estão"}{" "}
            aguardando a confirmação de pagamento
          </p>
        </>
      )}

      {paymentExpired.length > 0 && (
        <>
          <p>
            {oneExpired ? "O Plano " : "Os planos "} {paymentExpired.join(", ")}{" "}
            {oneExpired ? " expirou" : " expiraram"} o tempo de uso.
          </p>
        </>
      )}

      <p>
        Com isso os recursos{" "}
        {totalExpired ? " desses planos " : " desse plano "}
        não estarão disponíveis para uso.
      </p>
    </div>
  );
};
